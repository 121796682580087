
.linkList {
  
    list-style: none;
    letter-spacing: 0.08em;
    font-size: 50px;
    z-index: -10;
    padding-bottom: 20px;
}

.hoverImage img {
  /* display: none; */
 
  width: 200px;
  height:200px;
  /* z-index: 100; */
}

 

.hoverImage:hover img {
 
  display: block;
 position: absolute;
 /* z-index:999; */
 

}

/* .hoverImage a {
 z-index:100;


} */



.project-list {
  list-style-type: none;

}

.project-list__item{
  padding-right: 20px;
  font-size: 23px;
  margin-top: 5px;
}

/* .project-list__item:hover{

  color:crimson;
  font-family:"YoungSerif";
  text-decoration: none;



} */

.project-list__item-link{
 padding-top: 20px;
  position: relative;
  z-index: 2;
  text-decoration: none;
  color: black;
}

.project-list__item-link:visited {
  
  color: grey;
   }
  


.project-list__item-image {
  position:relative;
  width: 90vw;
  height: 50vh;
  object-fit: cover;
  margin-top: 27px;
  
  z-index: 1;
  border: 2px solid black;
  border-radius: 50px;
  box-shadow: 12px 12px 2px 1px rgba(0, 0, 255, .2);
}

.project-list__item-slide {
  position: absolute;
  width:100%;
  top:5px;
  left:5px;

}
.project-list__item{
  align-items:flex-end;
  display: flex;
  flex-direction: column-reverse;

 }
.project-list_item:hover{
  color: crimson;
}
 

@media (min-width: 900px) {
 

  .project-list__item{
  
    align-items:flex-start;
   margin-top: 80px;
  
  
   }

  .project-list__item-image {
   
    visibility: hidden;
    top: 0;
    left: 0;
    right: 0;
    /* width: auto;
    height: auto; */
    width: 50vw;
    height: 100vh;
    border: 2px solid black;
    box-shadow: 12px 12px 2px 1px rgba(0, 0, 255, .2);
    border-radius: 50px;
    max-width: 500px;
    max-height: 65vh;
    margin: auto;
    position: fixed;
    object-fit: cover;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 27px;
  
  }
  

  .project-list__item-link:hover + .project-list__item-image {
    
    mix-blend-mode:color-burn;
    isolation: revert;
    visibility: visible;
    
  }

  .project-list__item-link{
   
    font-size: 50px;
    text-decoration: none;
    letter-spacing:-0.55px;
    font-weight: 300;
    line-height: 1.55px;
    word-spacing: -0.55px;
    opacity: 1;
    padding-top: 100px;
  
  }

 
  .project-list__item-link:hover{
    color: crimson !important; 
    letter-spacing:-1px;
    text-decoration: none;
    font-family: "Voyage-Regular";    
    font-weight:200;
    letter-spacing: 1.75px;
    line-height: 1.565px;
    word-spacing: 1.92px;
    
  }

  .project-list__item-link:visited{
   
    color:black;
    
  
  }

  .project-list__item-image{
    margin-top: 27px;
   
    
  }
  .hoverImage:hover img {
 
  display: block;
 position: absolute;
 z-index:999;
 

}
  

}
