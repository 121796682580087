
.reviewItemOpen {
    text-align:justify;
    padding: 25px 0 20px 20px;
   
} 


.reviewItemClose {
    padding-bottom: 50px; 
    padding: 25px 0 20px 20px;
    text-align:justify;
} 

.reviewButton {
    align-self: flex-end;
    position:relative;
    border: none;
    text-decoration: underline;
    background: transparent;
    color: teal;
    padding-left:0;
}

.reviewButtonSeeMore{
    display: inline;
    

}

.review-identation{
    font-size: 1.2rem;
    line-height: 1.5;
}

.image-and-details {
    display: flex;
   padding: 20px 0 5px 45px;

}

.imageReview {
    transform: translate(9%, 2%);
    border: 1px solid slategray;
    filter: sepia(10%);
    margin-right: 15px;
    width: 64px;
    height: 64px;
    border-radius: 50px;
    
}

.rating {
    display: flex;
    flex-direction: column;
   
}

.review-owner-name {
    font-family: "Voyage-Regular";
    font-weight: 900;
    letter-spacing: 0.25em;
    font-size: 20px;
}

.review-blockquote img{
    width:30px;
    height:30px;

}


.review-wrapper {
     
    width: 100%;  

}

.review-quote-header {
    display: flex;
   
}

.review-dates {
    display: flex;
    flex-direction: column;
    font-size: 0.8em;
    color: rgb(130, 128, 142);
}
