
.review-card-layout {
    width: 100%;
}

.review-card-layout-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1200px;
 
}

.review-header {
    justify-content: flex-start;
    display: flex;
    align-items: flex-end;
    /* margin: 0 auto; */
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;

}


.review-header h4 {
    margin-left: 7px;
    font-size: 3em;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-color: #272727;
    -webkit-text-stroke-width: 1px;    
}


@media (min-width: 767px) {

.review-card-layout-wrapper {
    margin: 0 auto;
    max-width: 1200px;
    flex-direction: row;
}

.review-card-layout{
    width: 50%;
    /* padding: 20px;
    margin-left: 50px; */

}

.review-header h1 {
    margin-left: 8px;
    margin-bottom: 35px;
}
    
    
}


